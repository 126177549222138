.pointer {
    cursor: pointer !important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #3498db;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fa-26 {
    font-size: 26px !important;
}

.fa-24 {
    font-size: 24px !important;
}

.fa-22 {
    font-size: 22px !important;
}

.fa-20 {
    font-size: 20px !important;
}

.fa-19 {
    font-size: 19px !important;
}

.fa-18 {
    font-size: 18px !important;
}

.fa-17 {
    font-size: 17px !important;
}

.fa-16 {
    font-size: 16px !important;
}

.fa-15 {
    font-size: 15px !important;
}

.fa-14 {
    font-size: 14px !important;
}

.fa-13 {
    font-size: 13px !important;
}

.fa-12 {
    font-size: 12px !important;
}

.fa-11 {
    font-size: 11px !important;
}

.fa-10 {
    font-size: 10px !important;
}

.fa-in {
    font-size: inherit !important;
}

.del-red {
    color: #FF6865;
}

.cus-inpt {
    width: 100%;
    border: 1px solid #bebdbd;
    padding: 10px;
    border-radius: 4px;
    outline-color: #0d6efd;
}

.icon-btn {
    border: none;
    border-radius: 50%;
    font-size: 16px;
}

.cus-check {
    border: 1px solid #bebdbd;
    border-radius: 10px;
    height: 20px;
    width: 20px;
}


.sticky-area {
    position: sticky;
    top: 0;
}

.drag-and-drop {
    width: 100%;
    padding: 15px;
    border: 2px dashed #66afe9;
    /* border-color: #66afe9; */
    background-color: #eaf4fe;
    border-radius: 10px;
    outline: none;
    resize: none;
    font-size: 16px;
    transition: border-color 0.3s ease, background-color 0.3s ease;
}

.drag-and-drop:focus {
    border-color: #66afe9;
    background-color: #eaf4fe;
}

.drag-and-drop.dragover {
    border-color: #66afe9;
    background-color: #eaf4fe;
}